<template>
	<div class="submenu-container">
		<span class="mobile-dropdown" :class="{ active }" @click="active = !active" />
		<div class="submenu" :class="{ active: hideAll ? resetState() : active }">
			<ul>
				<li v-for="item in subitems" :key="item.filename">
					<nuxt-link :to="`${item.filename}`" @click="hideMenu">
						{{ item.header }}
					</nuxt-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
defineProps({
	subitems: { type: Array, default: () => [] },
	hideAll: { type: Boolean, default: false },
});

const emit = defineEmits(['hide-menu']);

const active = ref(false);

const resetState = () => {
	active.value = false;
};

const hideMenu = () => {
	emit('hide-menu');
};
</script>

<style lang="scss" scoped>
a {
	font-weight: 300;
	color: $primary-text-color;
	text-decoration: none;
	margin: 4px 0;
	font-size: $secondary-font-size;

	&.active,
	&:hover {
		color: $primary-color;
	}
}

.mobile-subnav {
	display: none;
}

.desktop-subnav {
	display: none;
}

.mobile-dropdown {
	width: 22px;
	height: 22px;
	background: url('~/assets/images/icon-form-dropdown.png') center center no-repeat;
	line-height: 22px;
	vertical-align: middle;
	cursor: pointer;
	margin-top: -3px;
	display: none;

	&.active {
		transform: rotate(180deg);
	}
}

.submenu {
	list-style: none;
	padding: 10px 0 0;

	&.active {
		display: block;
	}

	li,
	li a {
		white-space: nowrap;
		font-size: 14px;
		line-height: 26px;
	}
}

@media (min-width: 961px) {
	.main-nav li {
		&:hover {
			.desktop-subnav {
				position: absolute;
				top: 23px;
				left: 0;
				display: inline-block;
				text-align: left;
				padding: 13px 20px;
				background: #fff;
				box-shadow: 0 20px 30px rgb(0 0 0 / 15%);

				li {
					width: 100%;
					margin: 6px 0;
				}
			}

			.submenu {
				display: block;
			}
		}
	}
}

@media (max-width: 960px) {
	.mobile-subnav {
		display: block;
	}

	.mobile-dropdown {
		display: inline-block;
	}

	.desktop-subnav {
		display: none;
	}
}
</style>
