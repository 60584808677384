<template>
	<div>
		<nav class="top-nav fix-on-scroll">
			<div class="row">
				<div class="columns column12">
					<nuxt-link
						:to="{ path: localePath('/'), hash: route.name.startsWith('index') ? '#top' : '' }"
						class="logo"
					/>
					<div class="nav-buttons">
						<ul class="main-nav" :class="{ active: showMenu }">
							<li class="close-li">
								<div class="menu-close" @click="hideMenu">
									<font-awesome-icon :icon="`fal fa-times`" size="1x" />
								</div>
							</li>
							<li v-for="item in topmenu[locale]" :key="item.filename">
								<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
									{{ item.header }}
								</nuxt-link>
								<submenu
									v-if="item.submenu.length"
									:hide-all="!showMenu"
									:item="item"
									:subitems="item.submenu"
									@hide-menu="hideMenu"
								/>
							</li>
							<li v-if="page" class="mobile-lang-nav">
								<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
									<nuxt-link
										class="flag"
										:class="item.language"
										:to="`${item.filename}`"
										@click="hideMenu"
									>
										{{ $t(`${item.language}Long`) }}
									</nuxt-link>
								</div>
							</li>
						</ul>
						<div class="nav-buttons buttons-border">
							<lang-select :items="page.langmenu" />
							<div class="menu-button" @click.prevent="openMenu">
								<font-awesome-icon :icon="`fal fa-bars`" size="1x" />
							</div>
							<book-button class="button book-button reserve-now">
								<font-awesome-icon class="icon-margin" :icon="`fal fa-calendar-alt`" size="1x" />
								<span>{{ $t('bookNow') }}</span>
							</book-button>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<header v-if="!(page && page.headingImage)" class="no-slider" />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();

const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const openMenu = () => {
	showMenu.value = true;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};
</script>

<style lang="scss" scoped>
header.no-slider {
	width: 100%;
	height: 90px;
}

.locked .top-nav {
	z-index: 101;
}

.menu-button {
	display: none;
	border: 2px solid #000;
	color: #000;
	font-size: 22px;
	height: 50px;
	padding: 0 13px;
	text-align: center;
	flex-flow: nowrap row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.top-nav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 18;
	padding: 25px 0;
	background: $background-color-light;
	box-shadow: 0 20px 30px rgb(0 0 0 / 15%);

	.column12 {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		gap: 10px;
		align-items: center;
	}

	.row {
		overflow: visible;
	}

	.lang-nav {
		margin: 0 0 25px;
		text-transform: uppercase;

		img {
			width: 19px;
			float: left;
			margin: 0 5px 0 0;
		}

		a {
			width: 100%;
			float: left;
			color: $primary-text-color;
			text-decoration: none;
			margin: 4px 0;
			font-size: $secondary-font-size;
			font-weight: 500;
			text-transform: uppercase;

			&.active {
				color: $primary-color;
			}
		}
	}
}

.language-navigation:hover .lang-nav-sel,
.language-navigation:hover .fa-globe {
	color: $primary-color;
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown-language.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: $primary-text-color;
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-select {
	padding: 1px 10px 1px 15px;
	cursor: pointer;
	position: relative;
	text-transform: uppercase;

	i {
		font-size: 12px;
	}
}

.language-navigation .fa-globe {
	margin: 0 -5px 0 5px;
	color: #fff;
	transition: color 0.3s ease-in-out;
}

.logo {
	background: url('~/assets/images/logo-hotela2-full-large.png') center center no-repeat;
	background-size: 160px;
	width: 160px;
	height: 139px;
	position: absolute;
	top: 0;
	left: 20px;
}

.fixed .logo {
	background: url('~/assets/images/logo-hotela2-full-small.png') center center no-repeat;
	background-size: 80px;
	width: 80px;
	height: 70px;
	top: -14px;
}

.main-nav {
	list-style: none;
	z-index: 4;
	text-align: right;

	.close-li,
	.mobile-lang-nav {
		display: none;
	}

	a {
		color: $primary-text-color;
		padding: 5px 0;
		text-decoration: none;
		font-weight: 500;

		&:hover,
		&.active {
			color: $primary-color;
		}
	}

	li {
		display: inline-block;
		position: relative;
		text-transform: uppercase;
		margin: 0 12px;
		letter-spacing: 1px;
		font-size: $secondary-font-size;
	}
}

.nav-buttons {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	gap: 10px;
	align-items: center;

	&.buttons-border {
		border-left: 1px solid #bdbdbd;
		padding-left: 10px;
		margin-left: 10px;
	}
}

.text-nav {
	z-index: 1;
	position: relative;
}

.book-button {
	color: #fff;
	background: $primary-color;
	line-height: 22px;
	padding: 10px 35px 10px 19px;

	svg {
		margin: 0 7px 0 0;
	}
}

.main-nav li a {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $primary-color;
		transform: scaleX(0);
		transform-origin: bottom left;
		transition: transform 0.3s;
	}
}

.main-nav li a:hover::after {
	transform: scaleX(1);
}

@media (max-width: 1080px) {
	.main-nav {
		li {
			margin: 0 8px;
		}
	}

	.top-nav {
		.column12 {
			padding: 0 15px;
		}
	}

	.nav-buttons {
		&.buttons-border {
			padding-left: 6px;
			margin-left: 6px;
		}
	}

	.book-button {
		padding: 10px 25px 10px 19px;
	}
}

@media (max-height: 700px) {
	.logo {
		background: url('~/assets/images/logo-hotela2-full-small.png') 50% no-repeat;
		background-size: 80px;
		width: 80px;
		height: 70px;
		top: -14px;
	}
}

@media (max-width: 960px) {
	.logo {
		background: url('~/assets/images/logo-hotela2-full-medium.png') center center no-repeat;
		background-size: 100px;
		width: 100px;
		height: 87px;
		top: 0;
	}

	.menu-button {
		opacity: 1;
		z-index: 100;
		display: flex;
	}

	.nav-buttons.buttons-border {
		border: none;
		padding: 0;
		margin: 0;
	}

	.language-navigation,
	.lang-select {
		display: none;
	}

	.book-button {
		padding: 14px 25px;
	}

	.mobile-lang-nav {
		gap: 15px 0;
	}

	.mobile-lang-nav .flag::before {
		margin-right: 5px;
	}

	.main-nav {
		position: fixed;
		background: rgba(227 227 239 / 90%);
		backdrop-filter: blur(17px);
		padding: 50px;
		inset: 0;
		opacity: 0;
		z-index: 999;
		transition: all 0.5s ease-in-out;
		transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);

		&.active {
			transform: matrix(1, 0, 0, 1, 0, 0);
			opacity: 1;
		}

		.close-li {
			display: block;
			margin: 0 0 30px;
			font-size: 28px;
			cursor: pointer;
			text-align: right;
		}

		.mobile-lang-nav {
			display: flex;
			flex-flow: wrap column;
			margin: 30px 0 0;
			padding: 30px 0 0;
			border-top: 2px solid #9897bd;
			font-size: 14px;

			a {
				margin: 8px 0;
				width: 100%;

				&::after {
					display: none;
				}
			}

			img {
				max-width: 19px;
				margin: 0 8px 0 0;
			}
		}

		li {
			width: 100%;
			text-align: left;
			font-size: 22px;
			margin: 10px 0;
			text-transform: none;

			a {
				font-weight: 700;
			}
		}
	}

	.text-nav .main-nav {
		display: none;
	}
}

@media (max-width: 600px) {
	.top-nav {
		padding: 18px 0;

		.column12 {
			padding: 0 10px;
		}

		.logo {
			left: 10px;
		}
	}

	.nav-buttons {
		gap: 3px;
	}

	.book-button {
		padding: 13px 20px 12px;
	}

	.menu-button {
		height: 47px;
	}
}
</style>
