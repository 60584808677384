<template>
	<div class="lang-select" @click="toggleLangNav">
		<span>{{ locale }}</span>
		<font-awesome-icon :icon="`far fa-chevron-down`" size="1x" />
		<div v-if="showLangNav" class="lang-wrapper">
			<div v-for="item in items" :key="`${item.filename}-${item.language}`">
				<nuxt-link class="flag" :class="item.language" :to="`${item.filename}`" @click="hideLangNav">
					{{ $t(`${item.language}Short`) }}
				</nuxt-link>
			</div>
		</div>
	</div>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	items: { type: Array, default: () => [] },
});

const showLangNav = ref(false);

const toggleLangNav = () => {
	showLangNav.value = !showLangNav.value;
};

const hideLangNav = () => {
	showLangNav.value = false;
};
</script>

<style lang="scss" scoped>
.lang-select {
	max-width: 75px;
	padding: 12px 15px;
	cursor: pointer;
	position: relative;
	text-transform: uppercase;
	display: flex;
	align-items: center;

	i {
		font-size: 12px;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 1px;
	right: 1px;
	padding: 10px 9px;
	text-align: center;
	background: $primary-background-color;
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;

	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
		line-height: 25px;
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 19px;
		height: 19px;
		margin: 2px 0 0;
	}
}
</style>
