<template>
	<Link v-if="canonical" rel="canonical" :href="config.public.siteUrl + canonical.filename" />
	<Link
		v-for="item in langmenu"
		:key="item"
		rel="alternate"
		:hreflang="item.language"
		:href="config.public.siteUrl + item.filename"
	/>
	<Link v-if="xDefault" rel="alternate" hreflang="x-default" :href="config.public.siteUrl + xDefault.filename" />
</template>

<script setup>
const config = useRuntimeConfig();
const { locale } = useI18n();

const canonical = ref(null);
const xDefault = ref(null);

const props = defineProps({
	langmenu: { type: Array, default: () => [] },
});

watchEffect(() => {
	canonical.value = props.langmenu.find((item) => item.language === locale.value);
	xDefault.value = props.langmenu.find((item) => item.language === 'en');
});
</script>
